.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.custom-scrollbar {
  scrollbar-width: thin; 
  scrollbar-color: #33BA57 transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  cursor: pointer;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
  cursor: pointer;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #33BA57;
  border-radius: 5px;
  border: 0;
  background-clip: padding-box;
  cursor: pointer;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #2ca94f;
  cursor: pointer;
}

.dark .custom-scrollbar {
  scrollbar-color: #33BA57 rgba(0, 0, 0, 0.2);
}

.dark .custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #33BA57;
  border-radius: 5px;
}

.dark .custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #2ca94f;
}