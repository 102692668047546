@import url('https://fonts.googleapis.com/css2?family=Playwrite+IN:wght@100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "Noto Sans", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.price {
  font-family:  "PT Serif", serif!important;
  font-weight: 700!important;
  text-align: center!important;
  font-style: normal;
}
html{
  background-color: #F3F4F6;
}
html.dark {
  background-color: #1F1F1F; /* Dark mode background */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body.dark{
  background-color: #1F1F1F;
}


.custombutton {
  position: relative;
  overflow: hidden; 
  transition: all 0.3s ease;
}

.custombutton::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 50%;
  transform: scale(1);
  transition: 0s;
}

.custombutton:hover::after {
  opacity: 1;
  transform: scale(100);
  transition: all 1s ease-out;
}

.dark .custombutton::after {
  background: rgba(255, 255, 255, 0.2);
}

.custombutton.bg-primary:hover::after {
  background: rgba(255, 255, 255, 0.3);
}

.custombutton.ripple {
  background-position: center;
  transition: background 0.8s;
}

.custombutton.ripple:active {
  background-color: transparent;
  background-size: 100%;
  transition: background 0s;
}

.custombutton.ripple:active::after {
  opacity: 1;
  transform: scale(0);
  transition: 0s;
}


/* Custom scrollbar for all browsers */
.custom-scrollbar {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #5750F1 #e5e7eb; /* Thumb & Track color */
  border-radius: 10px;
}

/* Webkit-based browsers (Chrome, Safari, Edge) */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #e5e7eb; /* Gray-300 */
  border-radius: 10px;
  border: 2px solid #e5e7eb;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #5750F1; /* Gray-500 */
  border-radius: 10px;
  border: 2px solid #e5e7eb;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #4b5563; /* Gray-600 */
}
